import { useAsyncPrivateAPI } from "~/uses/useMyFetch";

export const useContentfulStore = defineStore({
  id: "contentfulStore",
  state: () => {
    return {
      slider: [],
      blog: [],
      video: [],
      faq: [],
      works: [],
      underFilterSlider: [],
      installations: [],
    };
  },
  actions: {
    async fetchBlog(quantity = undefined) {
      const { data } = await useAsyncPrivateAPI("/contentful/blog", {
        params: {
          quantity,
        },
      });
      const comparisonResult = data.filter(
        (el1) => !this.blog.some((el2) => el2.sys.id === el1.sys.id),
      );

      if (comparisonResult.length) {
        comparisonResult.forEach((el) => this.blog.push(el));
      }
    },
    async fetchBlogBySlug(slug) {
      if (!this.blog.some((el) => el.fields.url.ru === slug)) {
        const { data } = await useAsyncPrivateAPI("/contentful/blog", {
          params: {
            slug,
          },
        });

        this.blog.push(data);
      }
    },
    async fetchSlider() {
      const { data } = await useAsyncPrivateAPI("/contentful/slider");
      this.slider = data;
    },
    async fetchVideo() {
      const { data } = await useAsyncPrivateAPI("/contentful/video");
      this.video = data;
    },

    async fetchFaq() {
      const { data } = await useAsyncPrivateAPI("/contentful/faq");
      this.faq = data;
    },

    async fetchWorks() {
      const { data } = await useAsyncPrivateAPI("/contentful/works");
      this.works = data;
    },

    async fetchUnderFilterSlider() {
      const { data } = await useAsyncPrivateAPI(
        "/contentful/underFilterSlider",
      );
      this.underFilterSlider = data;
    },

    async fetchInstallations(quantity = undefined) {
      await useAsyncPrivateAPI("/contentful/installations", {
        params: {
          quantity,
        },
      }).then(({ data }) => {
        data.forEach((dto) => {
          const isIncludes = this.installations.some(
            (installation) => installation.sys.id === dto.sys.id,
          );

          if (!isIncludes) {
            this.installations.push(dto);
          }
        });
      });
    },

    async fetchInstallationArticle(slug) {
      if (!this.installations.some((el) => el.fields.url.ru === slug)) {
        const { data } = await useAsyncPrivateAPI("/contentful/installations", {
          params: {
            slug,
          },
        });

        this.installations.push(data);
      }
    },
  },
  getters: {
    getSlider: (state) => state.slider,
    getSliderBySlug: (state) => {
      return (slug) => {
        return state.slider.find((item) => item.fields.url.ru === slug);
      };
    },

    getBlog: (state) => state.blog,
    getBlogBySlug: (state) => {
      return (slug) => {
        return state.blog.find((el) => el.fields.url.ru === slug);
      };
    },
    getLimitedBlog: (state) => {
      return (limit) => {
        return state.blog.slice(0, limit);
      };
    },

    getVideo: (state) => state.video,
    getFaq: (state) => state.faq,
    getWorks: (state) => state.works,
    getUnderFilterSlider: (state) => state.underFilterSlider,

    getInstallations: (state) => state.installations,
    getInstallationArticle: (state) => {
      return (slug) => {
        return state.installations.find((el) => el.fields.url.ru === slug);
      };
    },
    geLimitedInstallations: (state) => {
      return (limit) => {
        return state.installations.slice(0, limit);
      };
    },
  },
});
